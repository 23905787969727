import React from 'react';
import { graphql } from 'gatsby';
import HeaderMain from '../components/HeaderMain';
import Footer from '../components/Footer';
import SEO from '../components/seo';
import HeroSingle from '../components/pages/HeroSingle';
import WhyWe from '../components/pages/WhyWe';
import SpecializedEquipmentList from '../components/pages/SpecializedEquipmentList';
import PosterDownload from '../components/pages/PosterDownload';
import LineFull from '../images/linefull.jpg';
import '../styles/app.scss';

const WhyWeRock = ({ data }) => {
    const { wordpressPage: post } = data;
    const test_title = post.yoast_title;
    //const new_seo_title = test_title.replace('&#039;', "'");

    return (
        <>
            <HeaderMain />
            <HeroSingle pageTitle={post.title} />
            <WhyWe content={post.content} featImage={post.featured_media} />
            <SpecializedEquipmentList
                eqList={post.acf.specialized_pieces_list}
                eqTitle={post.acf.equipment_title}
            />
            <section>
                <div className="container">
                    <img
                        className="margin-0-auto"
                        src={LineFull}
                        alt="line full"
                    />
                </div>
            </section>
            <PosterDownload
                posterImage={post.acf.wwr_poster_image}
                posterContent={post.acf.wwr_poster_download_content}
            />
            <Footer />
        </>
    );
};

export default WhyWeRock;

export const pageQuery = graphql`
    query($id: String!) {
        wordpressPage(id: { eq: $id }) {
            id
            title
            slug
            featured_media {
                localFile {
                    childImageSharp {
                        fluid {
                            srcWebp
                            src
                        }
                    }
                }
            }
            content
            acf {
                equipment_title
                specialized_pieces_list {
                    equipment_name
                    equipment_image {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcWebp
                                    src
                                }
                            }
                        }
                    }
                    equipment_description
                    enquipment_encourages
                    equipment_star_image {
                        localFile {
                            childImageSharp {
                                fluid {
                                    srcWebp
                                    src
                                }
                            }
                        }
                    }
                }
                wwr_poster_download_content
                wwr_poster_image {
                    localFile {
                        childImageSharp {
                            fluid {
                                srcWebp
                                src
                            }
                        }
                    }
                }
            }
        }
    }
`;
